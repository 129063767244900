import {GetModule} from "@/Api/Basics";
import Storage from "@/util/Storage";

// 学校模块列表
export function getSchoolModuleList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    return Promise.resolve(GetModule({ userId,token },body).then(res=>{
        return res.data
    }))
}
